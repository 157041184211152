$(window).on('load', function () {
    expandHeader();
    amendHomeLinkOnHeaderLogo();

    moveBodyBelowFixedHeader();
    $(window).trigger('resize');

    if (TrackingConsentManager.canTrackCurrentUser()) {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-KBHPZZX');
    }
});

$(document).ready(function () {
    amendHomeLinkOnHeaderLogo();

    moveBodyBelowFixedHeader();

    if ($(window).width() > 768) {
        setDesktopHomePageHero();

        if (isCurrentPageHomepage()) {
            $('.mi-corpo-breadcrumb').hide();
        }
    }
    else {
        setMobileHomePageHero();
    }

    /**
     * Initialize mobile menu
     */
    $('#mi-corpo-mobile-menu-search-box').css('display', 'none');

    $('#menu').multilevelpushmenu({
        onItemClick: function ($item) {
            var event = arguments[0],
                // Second argument is menu level object containing clicked item (<div> element)
                $menuLevelHolder = arguments[1],
                // Third argument is clicked item (<li> element)
                $item = arguments[2],
                // Fourth argument is instance settings/options object
                options = arguments[3];
            // Anchor href
            var itemHref = $item.find('a:first').attr('href');
            // Redirecting the page
            location.href = itemHref;
        },
        onGroupItemClick: function () {
            var navigationSublevel = $(arguments[3].menu[0])[0];
            $(navigationSublevel).css('margin-left', '-9px');
        },

        onBackItemClick: function () {
            $(arguments[1][0]).css('margin-left', '-300px');
        },

        containersToPush: [$('#pushobj')],
        mode: 'cover'
    });

    $('#responsiveMenu').css('display', 'none');

    $('.mi-corpo-navbar-button').on('touchend click', function () {
        $('#responsiveMenu').css('display', 'block');
        $('body').css('overflow', 'hidden');
        return false;
    });

    $('#mi-corpo-mobile-menu-search-button').on('click', function () {
        if ($('#mi-corpo-mobile-menu-search-box').css('display') == 'none') {
            $('#mi-corpo-mobile-menu-search-box').css('display', 'inline-block');
        } else {
            $('#mi-corpo-mobile-menu-search-box').css('display', 'none');
        }
        return false;
    });

    $('#mi-corpo-mobile-menu-search-box-text-box-component').keyup(function (e) {
        if (e.keyCode == 13) {
            const LANGUAGE = $('html').attr('lang');
            var searchText = $('#mi-corpo-mobile-menu-search-box-text-box-component').val();
            var redirectedUrl = "/" + LANGUAGE + "/search?indexCatalogue=search-mi&searchQuery=" + searchText + "&wordsMode=AllWords";
            $(location).attr('href', redirectedUrl);
        }
    });

    $('#mi-corpo-header-menu-item-searchtext').keyup(function (e) {
        if (e.keyCode == 13) {
            const LANGUAGE = $('html').attr('lang');
            var searchText = $('#mi-corpo-header-menu-item-searchtext').val();
            var redirectedUrl = "/" + LANGUAGE + "/search?indexCatalogue=search-mi&searchQuery=" + searchText + "&wordsMode=AllWords";
            $(location).attr('href', redirectedUrl);
        }
    });

    $('#mi-corpo-mobile-menu-close-button').on('touchend click', function () {
        $('#responsiveMenu').removeAttr('style');
        $('#responsiveMenu').hide();

        $('body').css('overflow', 'unset');
        return false;
    });

    $('.mi-corpo-header-CareerMenuDots').on('click', function () {
        expandHeader();
    });

    $(window).on('resize scroll', function () {
        if ($(window).width() < 992 && window.scrollY == 0) {
            //$('.mi-corpo-header-CareerMenu').removeClass('col-sm-10');
            //$('.mi-corpo-header-CareerMenu').addClass('col-sm-9');
        }
        if ($(window).width() > 992 && window.scrollY == 0) {
            //$('.mi-corpo-header-CareerMenu').addClass('col-sm-10');
            //$('.mi-corpo-header-CareerMenu').removeClass('col-sm-9');
        }
    });

    $(window).on('resize', function () {
        if ($(window).width() > 768) {
            expandHeader();
            $('#responsiveMenu').hide();
            setDesktopHomePageHero();
        } else {
            setMobileHomePageHero();
        }
        moveBodyBelowFixedHeader();
        adjustFooterMenu();

    });

    $(window).on('scroll', function () {
        if ($(window).width() > 768) {
            if (window.scrollY == 0) {
                //user scrolled to the top of the page, then back to expanded menu
                expandHeader();
            } else {
                if (!$('#mi-corpo-header').hasClass('mi-corpo-header-homepagehero')) {
                    collapseHeader();
                }

            }
        }
    });

    $(".mi-corpo-mobile-menu").css("visibility", "visible");
    $(".mi-corpo-countrySelector-banner").css("display", "block");

    /*Checking headers top position if ConsentTracking dialog is active */

    positionHeaderInResizeWithCookiesDialog();

    $(window).resize(function () {
        positionHeaderInResizeWithCookiesDialog();
    });


    if ($('.mi-technology-page-content').length > 0) {
        $('.mi-corpo-header-breadcrumbs').hide();
        jQuery(window).resize(); // Use to trigger resize for the whole page.
    }

    $(window).trigger('resize');
});

function expandHeader() {
    $('.mi-corpo-header-mainmenu').removeClass('col-sm-8');
    $('.mi-corpo-header-mainmenu').addClass('col-lg-12');
    $('.mi-corpo-main-header').removeClass('mi-corpo-main-header-collapsed');
    $('.navigation-mi .mi-corpo-nav-link').removeClass('mi-corpo-nav-link-collapsed');
    $('.mi-corpo-header-mainmenu').removeClass('mi-corpo-header-mainmenu-collapsed');
    $('.mi-corpo-header-CareerMenu').removeClass('mi-corpo-header-CareerMenu-collapsed');
    $('.mi-corpo-header-CareerMenuDots').removeClass('mi-corpo-header-CareerMenuDots-collapsed');
    $('.mi-corpo-header-breadcrumbs').removeClass('mi-corpo-header-breadcrumbs-collapsed');
}

function collapseHeader() {
    $('.mi-corpo-header-mainmenu').removeClass('col-lg-12');
    $('.mi-corpo-header-mainmenu').addClass('col-sm-8');
    $('.mi-corpo-main-header').addClass('mi-corpo-main-header-collapsed');
    $('.navigation-mi .mi-corpo-nav-link').addClass('mi-corpo-nav-link-collapsed');
    $('.mi-corpo-header-mainmenu').addClass('mi-corpo-header-mainmenu-collapsed');
    $('.mi-corpo-header-CareerMenu').addClass('mi-corpo-header-CareerMenu-collapsed');
    $('.mi-corpo-header-CareerMenuDots').addClass('mi-corpo-header-CareerMenuDots-collapsed');
    $('.mi-corpo-header-breadcrumbs').addClass('mi-corpo-header-breadcrumbs-collapsed');
}

function amendHomeLinkOnHeaderLogo() {
    var amendedHomeLink = generateHomeLink();
    $(".mi-corpo-header-logo > a").each(function () {
        if ($(this).attr('href').includes('/home')) {
            $(this).attr('href', amendedHomeLink);
        }
    });
}

function generateHomeLink() {
    const URL_SEPARATOR = '/';
    const PROTOCOL_SEPARATOR = '//';

    var amendedHomeLink = document.location.protocol + PROTOCOL_SEPARATOR + document.location.hostname;

    var port = document.location.port;
    if (port != '') {
        amendedHomeLink = amendedHomeLink + ':' + port;
    }

    const LANGUAGE = $('html').attr('lang').toLowerCase();
    if (LANGUAGE !== 'en') {
        amendedHomeLink = amendedHomeLink + URL_SEPARATOR + LANGUAGE;
    }

    return amendedHomeLink;
}

function moveBodyBelowFixedHeader() {
    var headerHeight = $('.mi-corpo-header-area').height();
    var consentTrackingHeight = $("#tracking-consent-dialog").height();
    var miCorpoBodyAreaClass = $('.mi-corpo-body-area');
    if (consentTrackingHeight == null) {
        consentTrackingHeight = 0;
    }

    if (miCorpoBodyAreaClass.width() >= 768) {
        miCorpoBodyAreaClass.css('padding-top', headerHeight + consentTrackingHeight + 10);
    }

    if (miCorpoBodyAreaClass.width() <= 768) {
        miCorpoBodyAreaClass.css('padding-top', Math.max(headerHeight + consentTrackingHeight + 30, 100));
    }
}

function positionHeaderInResizeWithCookiesDialog() {
    if ($("#tracking-consent-dialog").length == 1) {
        var headerHeight = $("#tracking-consent-dialog").height();
        $(".mi-corpo-header-area").css("top", headerHeight + 32);
    }
}

/*
 This function to set the mobile homepagehero image as a main header background image
*/
function setMobileHomePageHero() {
    var mobileImagePath = $(".mi-mobile-homepagehero").css('background-image');
    $(".mi-corpo-header-homepagehero").css('background-image', mobileImagePath);
}

function setDesktopHomePageHero() {
    var desktopImagePath = $(".mi-desktop-homepagehero").css('background-image');
    $(".mi-corpo-header-homepagehero").css('background-image', desktopImagePath);
}


/*** FOOTER  ****/

function adjustFooterMenu() {
    if ($(window).width() > 960) {

        $('[id^="collapse"]').each(function () {
            $("#" + $(this).attr('id')).addClass("show");
        });

        $('[id^="heading"]').each(function () {
            $("#" + $(this).attr('id') + " > a").attr('data-toggle', '');
        });

        $('[id^="mi-footermenu-header-expandarrow"]').each(function () {
            $("#" + $(this).attr('id')).hide();
        });

        $('[id^="mi-footermenu-header-underline"]').each(function () {
            $("#" + $(this).attr('id')).hide();
        });
    }
    else {

        $('[id^="collapse"]').each(function () {
            $("#" + $(this).attr('id')).removeClass("show");
        });

        $('[id^="heading"]').each(function () {
            $("#" + $(this).attr('id') + " > a").attr('data-toggle', 'collapse');
        });

        $('[id^="mi-footermenu-header-expandarrow"]').each(function () {
            $("#" + $(this).attr('id')).show();
        });

        $('[id^="mi-footermenu-header-underline"]').each(function () {
            $("#" + $(this).attr('id')).show();
        });
    }

}

function isCurrentPageHomepage() {
    const EMPTY_STRING = '';
    const URL_SEPARATOR = '/';

    const LANGUAGE = $('html').attr('lang');
    const LANGUAGE_PATHNAME_PREFIX = URL_SEPARATOR + LANGUAGE;

    let pathname = window.location.pathname.toLowerCase();

    if (pathname.length >= LANGUAGE_PATHNAME_PREFIX.length &&
        pathname.substring(0, LANGUAGE_PATHNAME_PREFIX.length) === LANGUAGE_PATHNAME_PREFIX) {

        pathname = pathname.substring(LANGUAGE_PATHNAME_PREFIX.length);
    }

    pathname = pathname.replaceAll(URL_SEPARATOR, EMPTY_STRING);

    return (pathname === EMPTY_STRING || pathname === 'home');
}